<template>
  <v-card class="mx-auto mb-0 pt-0 mt-0">
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel key="0">
        <v-expansion-panel-header>
          {{ $t("syncSchedule") }}
          <v-spacer></v-spacer>
          <span v-if="isActive" class="ml-6 subtitle-2 font-weight-regular">
            {{ $t("nextSync") }}
            {{ $t("timeEntries") }}
            {{ fetchTimeEntriesNextSync }}
            |
            {{ $t("configurationObjects") }}
            {{ fetchConfigObjectsNextSync }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <time-synchronization-choser
            :title="$t('configObjectsSync')"
            :connectionBoxId="connectionBoxId"
          />
          <time-synchronization-choser
            :title="$t('timeEntriesSync')"
            :connectionBoxId="connectionBoxId"
          />
          <webhook-settings
            v-if="showWebhookSettings"
            :connectionBox="currentConnectionBox"
          ></webhook-settings>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { isMembershipHobby } from "@timer2ticket/timer2ticket-client-vue-library";
import { EventBus } from "../../../EventBus";
import { Constants } from "../../../constants/Constants";
import { getTimeFormatOptions } from "../shared";
import { MembershipType } from "@timer2ticket/timer2ticket-client-vue-library";

export default {
  components: {
    "time-synchronization-choser": () =>
      import("./TimeSynchronizationChoser.vue"),
    "webhook-settings": () => import("./WebhookSettings.vue"),
  },
  mounted() {
    this.panel = [];

    EventBus.$on("connection-box-saved", (oldId, newId) => {
      if (oldId === this.connectionBoxId) {
        this.connectionBoxId = newId;
      }
      this.fetchCurrentConnectionBox();
    });
  },
  data() {
    return {
      configObjectsNextSync: "",
      timeEntriesNextSync: "",
      currentConnectionBox: null,
      panel: [0],
    };
  },
  computed: {
    isActive() {
      return this.currentConnectionBox.isActive;
    },

    fetchConfigObjectsNextSync() {
      return this.calculateNextTime(
        this.currentConnectionBox.schedule.configObjects.schedule
      );
    },
    fetchTimeEntriesNextSync() {
      return this.calculateNextTime(
        this.currentConnectionBox.schedule.timeEntries.schedule
      );
    },
    showWebhookSettings() {
      if (Constants.IS_COMMERCIAL_VERSION) {
        const memebershipName = this.$store.state.membership.name;
        if (memebershipName === "Senior") {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
  methods: {
    // Connection box methods
    fetchCurrentConnectionBox() {
      this.currentConnectionBox = this.getCurrentConnectionBox();
      this.fetchConfigObjectsNextSync;
      this.fetchTimeEntriesNextSync;
    },

    getCurrentConnectionBox() {
      let connectionBox = null;
      for (let i = 0; i < this.$store.state.userConnectionBoxes.length; ++i) {
        if (
          this.connectionBoxId ===
          this.$store.state.userConnectionBoxes[i].connectionBoxId
        ) {
          connectionBox = this.$store.state.userConnectionBoxes[i];
          break;
        }
      }
      return connectionBox;
    },
    calculateNextTime(schedule) {
      if (!schedule) {
        return "";
      }
      const CronJob = require("cron").CronJob;
      const job = new CronJob(
        schedule,
        null,
        null,
        null,
        this.$store.state.user.timeZone
      );
      const nextDate = job.nextDates(1)[0];

      return nextDate.toLocaleString(
        getTimeFormatOptions(this.$store.state.user.timeZone)
      );
    },
  },
  created() {
    this.fetchCurrentConnectionBox();
  },
  props: ["connectionBoxId"],
};
</script>

<style scoped></style>
